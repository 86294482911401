"use client";
import Script from "next/script";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NextTopLoader from "nextjs-toploader";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";

// Font files can be colocated inside of `app`

import "./globals.css";
import { NunitoFont } from "../fonts/fonts-config";
import { useEffect } from "react";
import { SnackbarProvider } from "notistack";
export default function ClientLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  return (
    <html lang="en" className={`scroll-smooth ${NunitoFont.className}`}>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest"></link>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <body>
        <ToastContainer
          position="top-center"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />{" "}
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={1} className="snackbar">
            <NextTopLoader
              color="#264FFA"
              initialPosition={0.08}
              crawlSpeed={200}
              height={4}
              crawl={true}
              easing="ease"
              speed={200}
            />
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
          </SnackbarProvider>
        </QueryClientProvider>
        {/* Insert the Clarity script */}
        <Script id="clarity-analytics" strategy="afterInteractive">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "mycuzvbwj9");
          `}
        </Script>
        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=G-WKYC153G8C`}
        />
        <Script strategy="lazyOnload">
          {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-WKYC153G8C', {
                  page_path: window.location.pathname,
                  });
               `}
        </Script>
      </body>
    </html>
  );
}
